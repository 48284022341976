import axios from "axios";
import axiosConfig from "../../config/axios.config";

export async function getImage(url: string): Promise<any> {

    const res = await axios.get(url, {
        responseType: 'blob',
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    return URL.createObjectURL(res.data);
}