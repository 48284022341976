import { useEffect, useState } from "react";
import { Alert, Container, Form } from "react-bootstrap";
import FormInput from "../../components/FormInput.component";
import MainButton from "../../components/MainButton.component";
import { generateQuizLection, getLection, updateLection, updateLectionOnly } from "../../api/Index";
import { redirect, useLoaderData, useNavigate, useParams } from "react-router-dom";

export function loader({ params, request }: any) {
  const isLoggedIn = localStorage.getItem("auth");
  if (!isLoggedIn) {
    throw redirect("/login")
  }

  const url = new URL(request.url);
  const type = url.searchParams.get("type");

  return getLection(params.id);
}

export default function CreateQuiz() {
  const response: any = useLoaderData();
  const params = useParams()
  const [data, setData] = useState<any>(response);
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])


  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    }

    setValidated(true);

    const req = {
      idLezione: data.idLezione,
      quiz: form.description.value
    }

    updateLectionOnly(req)
      .then(() => {
        navigate("/lection/" + data.idLezione);
      });

  };

  const handleGenerate = (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    }

    setValidated(true);

    if (params.id && form.totaleDomande.value) {
      generateQuizLection({ id: params.id, totaleDomande: form.totaleDomande.value })
        .then((data: any) => {
          setData((prev: any) => ({ ...prev, quiz: data.quiz }))
        }).then(() => {
          setLoading(false);
        });
    }

  };

  useEffect(() => {
    setInputValue(data.quiz);
  }, [data.quiz]);

  return (
    <Container style={{
      justifyContent: "center",
      marginTop: 50
    }}>
      <Container className="text-center mb-4">
        <h1>Quiz</h1>
      </Container>
      <p><b>Corso: {data.titoloCorso}</b></p>
      <p><b>Lezione: {data.titoloLezione}</b></p>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form noValidate validated={validated} onSubmit={handleGenerate}>
        <FormInput
          required
          id={"totaleDomande"}
          type="number"
          placeholder="Numero di domande"
          defaultValue={data.totaleDomande}
        />
        <MainButton type="submit" text="Genera Quiz" loading={loading} />
      </Form>
      {
        data && data.quiz &&
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 20 }}>
          <FormInput id="description" type="textarea" placeholder="Descrizione" required={true} defaultValue={inputValue} style={{ height: 400 }} />
          <MainButton type="submit" text="Salva" loading={loading} />
        </Form>
      }
    </Container>

  );
}