import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div style={{ textAlign: "center", marginTop: 300 }}>
            <h1>Problema applicativo</h1>
            <p>Mi dispiace, al momento il servizio non è disponibile. Riprova piu tardi</p>
            <Link to="/">Vai alla home</Link>
        </div>
    );
};

export default ErrorPage;