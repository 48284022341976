import { Form, Row } from "react-bootstrap";
import { Colors } from "../config/colors.config";
import { useRef, useState } from "react";

export default function FormInput({
    ref,
    required,
    type,
    accept,
    placeholder,
    id,
    value,
    defaultValue,
    style,
    disabled,
    onChange
}: {
    ref?: any,
    required: boolean,
    type: string,
    accept?: string,
    placeholder: string,
    id: string,
    value?: any,
    defaultValue?: any,
    disabled?: any,
    style?: any,
    onChange?: (e: any) => void
}) {

    return (
        <Row className="mb-3">
            <Form.Group controlId={id}>
                {type === "file" ? <Form.Label
                    for={id}
                    style={{
                        cursor: "pointer",
                        border: "1px solid " + Colors.input.background,
                        width: "100%",
                        backgroundColor: Colors.input.background,
                        color: Colors.black,
                        textAlign: "center",
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}>{placeholder}</Form.Label> : null}
                <Form.Control
                    id={id}
                    ref={ref}
                    required={required}
                    type={type}
                    accept={accept}
                    as={type === "textarea" ? "textarea" : "input"}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    style={{
                        backgroundColor: Colors.input.background,
                        borderRadius: 10,
                        visibility: type === "file" ? "hidden" : "visible",
                        position: type === "file" ? "absolute" : "relative",
                        top: type === "file" ? 0 : "auto",
                        left: type === "file" ? 0 : "auto",
                        ...style,
                    }}
                    onChange={onChange}
                />
            </Form.Group>
        </Row>
    );
}