import { useState } from "react";
import { Alert, Container, Form, } from "react-bootstrap";
import FormInput from "../../components/FormInput.component";
import MainButton from "../../components/MainButton.component";
import { login } from "../../api/Index";
import { useNavigate } from "react-router-dom";

export function loader() {
  localStorage.removeItem("auth");
  return true
}

export default function Login() {
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    }

    setValidated(true);
    setTimeout(() => {
      login({
        email: form[0].value,
        password: form[1].value
      })
        .then((data: any) => {
          console.log("Then", data)
          if (data.status === 500) {
            setError(data.statusText)
          }
          navigate("/");
        })
        .catch((err: any) => {
          console.log("Catch", err)
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        })
    }, 3000)

  };

  return (
    <Container style={{
      width: "60%",
      maxWidth: 500,
      justifyContent: "center",
      margin: "0 auto",
      marginTop: "5%"
    }}>
      <Container className="text-center">
        <img src="/assets/logo/gen-ai.svg" width={300} />
        <p style={{ marginTop: 0, fontSize: 19, fontWeight: "600" }}>
          Comincia a creare le slide personalizzati per ogni argomento che vuoi esporre ai tuoi studenti.
        </p>
      </Container>

      {error && <Alert variant="danger">{error}</Alert>}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <FormInput id="email" type="email" placeholder="Email" required={true} />
        <FormInput id="password" type="password" placeholder="Password" required={true} />
        <MainButton type="submit" text="Accedi" loading={loading} />
      </Form>
    </Container>

  );
}