import { Button, Spinner } from "react-bootstrap";
import { Colors } from "../config/colors.config";

export default function MainButton({
    id = "",
    type = "button",
    text,
    style,
    loading = false,
    disabled = false,
    onClick
}: {
    id?: string,
    type: "button" | "submit" | "reset",
    text: string,
    style?: any,
    loading?: boolean,
    disabled?: boolean,
    onClick?: () => void
}) {
    return (
        <Button
            id={id}
            type={type}
            style={{
                backgroundColor: Colors.PRIMARY,
                color: Colors.white,
                borderColor: Colors.PRIMARY,
                width: "100%",
                fontSize: 16,
                fontWeight: "600",
                borderRadius: 10,
                ...style
            }}
            disabled={disabled || loading}
            onClick={onClick}
        >
            {
                loading ? <Spinner size="sm" /> :
                    text
            }
        </Button>
    );
}