import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Colors } from "../../config/colors.config";
import axiosConfig from "../../config/axios.config";
import axios from "axios";
import { redirect } from "react-router-dom";

export async function getCourses({ limit }: { limit: number }) {

    const url = `${axiosConfig.url}/courses?skip=${limit}`;

    let res
    try {
        res = await axios.get(url, {
            headers: {
                Authorization: axiosConfig.token()
            }
        });
    } catch (error) {
        throw redirect("/login")
    }

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    const data = res.data.data.map((course: any, i: number) => {
        let color = ""
        switch (course.status) {
            case "Completato":
                color = Colors.greenForest;
                break;
            case "In lavorazione":
                color = Colors.yellow;
                break;
        }
        return {
            data: [
                course.idCorso,
                course.codiceCorso,
                course.titoloCorso,
                course.totaleLezioni,
                course.lezioniCompletate,
                moment(course.dataCreazione).format("DD/MM/YYYY"),
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <FontAwesomeIcon icon={fas.faArrowRight} size="1x" style={{ padding: "5px 10px" }} />
                </div>

            ],
            clickable: true,
            editable: false
        }
    })
    return data
}

export async function getCourse({ id }: { id: string }) {
    const url = `${axiosConfig.url}/course/${id}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data.data
}

export async function getCourseDatatable({ id, limit }: { id: string, limit?: number }) {
    const url = `${axiosConfig.url}/course/datatable/${id}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data.data
}

export async function createCourses(data: {
    titoloCorso: string,
    descrizione: string,
    audio: any,
    argomenti: Array<any>
}) {

    const url = `${axiosConfig.url}/course`;

    const form = new FormData()
    form.append("data", JSON.stringify(data))
    form.append("file", data.audio[0])

    const res = await axios.post(url, form, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });


    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data
}

export async function updateCourses(data: {
    idCorso: number,
    titoloCorso: string,
    descrizione: string,
    audio: any,
    status: string
    argomenti: Array<any>
}) {
    const url = `${axiosConfig.url}/course`;

    const form = new FormData()
    form.append("data", JSON.stringify(data))
    if (data?.audio[0]) form.append("file", data.audio[0])

    console.log("data", data)
    const res = await axios.put(url, form, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });


    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data
}

export async function getCodeGeneration({ limit }: { limit: number }) {
    const url = `${axiosConfig.url}/queue?skip=${limit}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    const data = res.data.data.map((queue: any, i: number) => {
        let color = ""
        switch (queue.status) {
            case "Completato":
                color = Colors.greenForest;
                break;
            case "In lavorazione":
                color = Colors.yellow;
                break;
            case "Fallita":
                color = Colors.red;
                break;
        }
        return {
            data: [
                queue.fkLezione,
                queue.titoloCorso,
                queue.titoloLezione,
                moment(queue.dataCreazione).format("DD/MM/YYYY HH:mm:ss"),
                <div style={{ display: "flex" }}>
                    <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: color, marginTop: 8, marginRight: 10 }} />
                    {queue.status}
                </div>,
                queue.dataAggiornamento ? moment(queue.dataAggiornamento).format("DD/MM/YYYY HH:mm:ss") : "",
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <FontAwesomeIcon icon={fas.faArrowRight} size="1x" style={{ padding: "5px 10px" }} />
                </div>

            ],
            clickable: true,
            editable: false
        }
    })
    return data
}


export async function updateCourseArgumentsLectionsOrder(data: {
    argomenti: Array<any>
}) {
    const url = `${axiosConfig.url}/course/updateOrderArgumentsLections`;
    const res = await axios.put(url, data, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });


    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data
}
