import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import { downloadLection, getLection, singleDownloadLection, updateLectionsProgressiveCode } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import MainButton from "../../components/MainButton.component";
import { useEffect, useRef, useState } from "react";
import SlideItem from "../../components/SlideItem.component";
import Slide from "../../components/Slide.component";
import { Colors } from "../../config/colors.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalDelete from "../../components/ModalDelete.component";
import { Loader } from "../../App";



export function loader({ params }: any) {
    const isLoggedIn = localStorage.getItem("auth");
    if (!isLoggedIn) {
        throw redirect("/login")
    }

    return getLection(params.id);
}

export default function Lection() {

    const navigate = useNavigate();
    let response: any = useLoaderData();
    response.slides = response.slides.map((slide: any) => {
        const slideOBJ = JSON.parse(slide.testoBullet);
        return { ...slide, ...slideOBJ }
    });
    response.mdJSON = response.slides.map((slide: any) => {
        return {
            placeholder: "created",
            title: slide.title,
            bulletPoints: slide.content,
            metadata: slide
        }
    })

    const [data, setData] = useState<any>(response);
    const [mdJSON, setMdJSON] = useState<Array<any>>(response.mdJSON.map((md: any) => md.title));
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [showSlide, setShowSlide] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("idle");
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);

    const [play, setPlay] = useState<string>("pause");
    const [timeAudio, setTimeAudio] = useState<number>(0);
    const audio = new Audio(data.audioGenerated);
    const audioRef = useRef(audio);
    const scaleFactor = 1;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    useEffect(() => {

        const listener = audio.addEventListener('ended', () => setPlay("pause"));

        return () => {
            audio.removeEventListener("ended", () => setPlay("pause"));
        };
    }, []);

    useEffect(() => {
        if (play === "play") {
            audioRef.current.currentTime = timeAudio;
            audioRef.current.play()
        } else {
            audioRef.current.pause();
        }
    }, [play, timeAudio])

    useEffect(() => {
        console.log("Riordina...");
        let newArraySlides: any = [];
        let newArrayMd: any = [];

        mdJSON.map((currentTitle: string, index: number) => {
            const _slideIndex = data.slides.findIndex((slide: any) => slide.title === currentTitle)
            newArraySlides = moveElement(data.slides, _slideIndex, index)
            const _mdIndex = data.mdJSON.findIndex((md: any) => md.title === currentTitle)
            newArrayMd = moveElement(data.mdJSON, _mdIndex, index)
        });

        const _data = {
            ...data,
            mdJSON: newArrayMd,
            slides: newArraySlides
        }

        updateLectionsProgressiveCode(data.slides.map((slide: any, index: number) => {
            return {
                "fkLezione": data.idLezione,
                "idSlide": slide.idSlide,
                "codiceProgressivo": index
            }
        }))
        console.log("Set Data ->", data);
        setData((prev: any) => _data);
    }, [mdJSON])

    const moveElement = (arr: any, fromIndex: number, toIndex: number) => {
        if (fromIndex === toIndex) {
            return arr;
        }

        const [element] = arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);

        return arr;
    }


    const handleSave = (form: any) => {

        setShowModalConfirmDelete(true);

        //update slide image
        //data.mdJSON[activeIndex].bulletPoints = form.bullets;
        //data.mdJSON[activeIndex].title = form.title;
        //setStatus("idle");
        //setData(data);

        //post save slide e generation new image
    }

    const handleAdd = () => {
        data.mdJSON.push({
            placeholder: "created",
            title: "",
            bulletPoints: ["", "", ""],
            metadata: {
                testoAssociato: ""
            }
        })
        setData((prev: any) => data);
        setStatus("editing");
        setActiveIndex(data.mdJSON.length - 1);
        setShowSlide(true)
    }

    const handleDelete = (index: number) => {
        //api delete slide
        setShowModalConfirmDelete(true)
    }

    const handleClose = () => {
        setStatus("idle");
        setShowSlide(false)
    }

    const onModalDelete = () => {
        navigate(`/lection/create/${data.fkCorso}?type=edit&id=${data.idLezione}`)

    }

    const handleDownload = () => {
        downloadLection(data.idLezione).then((data: any) => {
            console.log("Download...", data.url)

            // Crea un link temporaneo per avviare il download
            const downloadLink = document.createElement('a');
            downloadLink.href = data.url;
            downloadLink.setAttribute('download', data.fileName); // Specifica il nome del file
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);

            // Simula un clic sul link per avviare il download
            downloadLink.click();

            // Rimuovi il link temporaneo dopo il download
            document.body.removeChild(downloadLink);
        })
    }

    const handleSingleDownload = (type: string) => {
        singleDownloadLection(data.idLezione, type).then((data) => {
            console.log("Download...", data.url)

            // Crea un link temporaneo per avviare il download
            const downloadLink = document.createElement('a');
            downloadLink.href = data.url;
            downloadLink.setAttribute('download', data.fileName); // Specifica il nome del file
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);

            // Simula un clic sul link per avviare il download
            downloadLink.click();

            // Rimuovi il link temporaneo dopo il download
            document.body.removeChild(downloadLink);
        })
    }

    if (loading) return <Loader />

    console.log(data)
    return (
        <Container>
            <ModalDelete show={showModalConfirmDelete} onSubmit={onModalDelete} onClose={() => setShowModalConfirmDelete(false)} />
            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                <p><a href="/" style={{ textDecoration: "none" }}>Corsi</a><a href={"/course/" + data.fkCorso} style={{ textDecoration: "none" }}> / Lezioni</a></p>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 30 }}>
                    <h1>Slide della lezione</h1>
                    <div className="d-flex">
                        <MainButton type="button" text="Modifica Lezione" onClick={() => handleDelete(1)} style={{ maxWidth: 200, height: 40, color: Colors.white }} />
                        <Dropdown
                            style={{ marginLeft: 10 }}
                            drop={"down-centered"}
                        >
                            <Dropdown.Toggle style={{ width: 100, height: 40, borderRadius: 10, backgroundColor: Colors.PRIMARY, color: Colors.white, border: 0 }} id="dropdown-basic" title="Azioni">
                                <span style={{
                                    fontSize: 16,
                                    fontWeight: "600",
                                    marginRight: 5
                                }}>Azioni</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleDownload}>Scarica zip</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSingleDownload('slide')}>Scarica slide</Dropdown.Item>
                                {data.quiz ? <Dropdown.Item onClick={() => handleSingleDownload('quiz')}>Scarica quiz</Dropdown.Item> : null}
                                {data.testo ? <Dropdown.Item onClick={() => handleSingleDownload('transcript')}>Scarica testo</Dropdown.Item> : null}
                                {data.slides[0].url_audio ? <Dropdown.Item onClick={() => handleSingleDownload('audio')}>Scarica audio</Dropdown.Item> : null}
                                {
                                    data.testo ?
                                        <>
                                            <Dropdown.Item onClick={() => { setLoading(true); navigate("/text/create/" + data.idLezione) }}>Rigenera testo</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setLoading(true); navigate("/codeGeneration?id=" + data.idLezione) }}>Genera audio</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setLoading(true); navigate("/quiz/create/" + data.idLezione) }}>Genera quiz</Dropdown.Item>
                                        </>
                                        :
                                        <Dropdown.Item onClick={() => { setLoading(true); navigate("/text/create/" + data.idLezione) }}>Genera testo</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>
                <b>Lezione</b>
                <p>{data.titoloLezione}</p>
                <b>Descrizione</b>
                <p>{data.descrizioneLezione}</p>

                {
                    data.audioGenerated ?
                        <>
                            <b style={{ marginTop: 8 }}>Lezione audio</b>
                            <div className="d-flex mt-2 w-100">
                                <button
                                    style={{ backgroundColor: Colors.PRIMARY, borderRadius: "50%", border: 0, width: 40, height: 40, marginRight: 10 }}
                                    onClick={() => setPlay(play === "play" ? "pause" : "play")}
                                >
                                    <FontAwesomeIcon icon={play === "play" ? fas.faPause : fas.faPlay} color={Colors.white} />
                                </button>
                                <input type="range" id="timeSlider" min="0" max={data.audioLength} step="1" value={timeAudio} onChange={(e: any) => setTimeAudio(e.target.value)} />
                                <span id="sliderValue" style={{ marginTop: 7, marginLeft: 10 }}>{data.audioLengthText}</span>
                            </div>
                        </>

                        : null
                }
                {data.quiz ? <p style={{ color: Colors.PRIMARY, cursor: "pointer", width: 100, marginTop: 10 }} onClick={() => navigate("/quiz/create/" + data.idLezione + "?type=edit")}>Vai al quiz</p> : null}
            </div>
            <Container className="w-100" style={{ height: 50 }}>
                <Dropdown style={{ float: "right" }} align="start">
                    <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: Colors.PRIMARY, borderRadius: 10, color: Colors.white, border: 0 }}>
                        <span style={{
                            fontSize: 16,
                            fontWeight: "600",
                            marginRight: 5
                        }}>Ordina</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: 400 }}>
                        <Reorder.Group axis="y" values={mdJSON} onReorder={setMdJSON}>
                            {mdJSON.map((item, index: number) => (
                                <Reorder.Item key={item} value={item} as="div" className="d-flex w-100" style={{ justifyContent: "space-between", paddingTop: 20, paddingRight: 20, cursor: "pointer" }}>
                                    <p style={{ marginTop: 2, fontWeight: "700", marginRight: 10 }}>{index} - {item}</p>
                                    <div style={{ width: 25, height: 25 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" width="25" height="25">
                                            <path d="M 5 0 C 7.761 0 10 2.239 10 5 C 10 7.761 7.761 10 5 10 C 2.239 10 0 7.761 0 5 C 0 2.239 2.239 0 5 0 Z" fill="#CCC"></path>
                                            <path d="M 19 0 C 21.761 0 24 2.239 24 5 C 24 7.761 21.761 10 19 10 C 16.239 10 14 7.761 14 5 C 14 2.239 16.239 0 19 0 Z" fill="#CCC"></path>
                                            <path d="M 33 0 C 35.761 0 38 2.239 38 5 C 38 7.761 35.761 10 33 10 C 30.239 10 28 7.761 28 5 C 28 2.239 30.239 0 33 0 Z" fill="#CCC"></path>
                                            <path d="M 33 14 C 35.761 14 38 16.239 38 19 C 38 21.761 35.761 24 33 24 C 30.239 24 28 21.761 28 19 C 28 16.239 30.239 14 33 14 Z" fill="#CCC"></path>
                                            <path d="M 19 14 C 21.761 14 24 16.239 24 19 C 24 21.761 21.761 24 19 24 C 16.239 24 14 21.761 14 19 C 14 16.239 16.239 14 19 14 Z" fill="#CCC"></path>
                                            <path d="M 5 14 C 7.761 14 10 16.239 10 19 C 10 21.761 7.761 24 5 24 C 2.239 24 0 21.761 0 19 C 0 16.239 2.239 14 5 14 Z" fill="#CCC"></path>
                                            <path d="M 5 28 C 7.761 28 10 30.239 10 33 C 10 35.761 7.761 38 5 38 C 2.239 38 0 35.761 0 33 C 0 30.239 2.239 28 5 28 Z" fill="#CCC"></path>
                                            <path d="M 19 28 C 21.761 28 24 30.239 24 33 C 24 35.761 21.761 38 19 38 C 16.239 38 14 35.761 14 33 C 14 30.239 16.239 28 19 28 Z" fill="#CCC"></path>
                                            <path d="M 33 28 C 35.761 28 38 30.239 38 33 C 38 35.761 35.761 38 33 38 C 30.239 38 28 35.761 28 33 C 28 30.239 30.239 28 33 28 Z" fill="#CCC"></path>
                                        </svg>
                                    </div>

                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
            <Container>
                <Row>
                    {
                        data.slides.map((slide: any, i: number) => (
                            <SlideItem
                                key={slide}
                                data={slide}
                                index={i}
                                onClick={(index: number) => { setStatus("idle"); setShowSlide(true); setActiveIndex(index) }}
                                onEdit={(index: number) => { setStatus("idle"); setShowSlide(true); setActiveIndex(index) }}
                                onDelete={handleDelete}
                            />

                        ))
                    }

                    <Col sm={12} md={6} lg={4}>
                        <div className="d-flex" style={{
                            width: "100%", height: 240, backgroundColor: Colors.white, marginTop: 50,
                            transform: `scale(${scaleFactor})`, justifyContent: "center", cursor: "pointer"
                        }} onClick={handleAdd}>
                            <div
                                style={{
                                    backgroundColor: Colors.PRIMARY, borderRadius: "50%", maxWidth: 35, maxHeight: 35,
                                    paddingTop: 5, paddingBottom: 5, paddingLeft: 11, paddingRight: 11, cursor: "pointer",
                                    marginTop: 90
                                }}
                            >
                                <FontAwesomeIcon size="1x" icon={fas.faPlus} style={{ width: 10 }} color={Colors.white} />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            <Slide
                status={status}
                show={showSlide}
                data={data.mdJSON}
                activeIndex={activeIndex}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        </Container>
    );
}

export const Item = ({ item }: any) => {
    const y = useMotionValue(0);
    const controls = useDragControls()

    return (
        <Reorder.Item value={item} id={item} style={{ y }} as="div" drag dragListener={false}
            dragControls={controls}>
            <span>{item}</span>
            <FontAwesomeIcon icon={fas.faBurger} onPointerDown={(event) => controls.start(event)} />
        </Reorder.Item>
    );
};