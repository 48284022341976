import { useEffect, useState } from "react";
import { Alert, Container, Form } from "react-bootstrap";
import FormInput from "../../components/FormInput.component";
import MainButton from "../../components/MainButton.component";
import { createCourses, generateTextLection, getLection, updateLection, updateLectionOnly } from "../../api/Index";
import { redirect, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../App";

export function loader({ params }: any) {
  const isLoggedIn = localStorage.getItem("auth");
  if (!isLoggedIn) {
    throw redirect("/login")
  }

  return getLection(params.id);
}

export default function CreateText() {
  const response: any = useLoaderData();
  const params = useParams()
  const [data, setData] = useState<any>(response);
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id && !data.testo) {
      setLoading(true)
      generateTextLection(Number(params.id))
        .then((res) => { setData(res); setLoading(false) })
        .catch((error) => { setError(error); setLoading(false) })
    }
  }, [])

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError("");
    setLoadingBtn(true);
    console.log(event)
    const form = event.currentTarget;
    console.log(event.nativeEvent.submitter.id)
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoadingBtn(false);
    }

    setValidated(true);

    if (event.nativeEvent.submitter.id === "btn-regen") {
      generateTextLection(Number(params.id))
        .then((res) => { alert("Testo generato"); setData(res); setLoadingBtn(false); })
        .catch((error) => { setError(error); setLoadingBtn(false); })
    } else {
      navigate("/lection/" + data.idLezione);
    }
  };

  console.log(data);
  useEffect(() => {
    setInputValue(data.testo);
  }, [data.testo]);

  if (loading) return <Loader />
  return (
    <Container style={{
      maxWidth: "80%",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: 50
    }}>
      <Container className="text-center">
        <h1>Testo della lezione</h1>
      </Container>
      <b>Corso di {data.titoloCorso}</b><br />
      <b>Lezione {data.titoloLezione}</b><br />
      <b>Descrizione Lezione</b><br />
      <p>{data.description}</p>
      {error && <Alert variant="danger">{error}</Alert>}
      {
        data && data.testo &&
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <FormInput id="description" type="textarea" placeholder="Descrizione" required={true} defaultValue={inputValue} disabled={true} style={{ height: 300 }} />
          {/*<MainButton type="submit" text="Salva" loading={loadingBtn} />*/}
          <MainButton id="btn-regen" type="submit" text="Rigenera testo" loading={loadingBtn} style={{ marginTop: 10 }} />
          <MainButton type="submit" text="Torna alle slide" loading={loadingBtn} style={{ marginTop: 10 }} />
        </Form>
      }
    </Container>

  );
}