import { Container, Dropdown, DropdownMenu, DropdownToggle, Modal, Stack } from "react-bootstrap";
import { getCourse, getCourseDatatable, updateCourseArgumentsLectionsOrder } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import MainButton from "../../components/MainButton.component";
import TableResponsive from "../../components/TableResponsive.component";
import { useEffect, useState } from "react";
import { Colors } from "../../config/colors.config";
import { Reorder } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

export function loader({ params }: any) {
    const isLoggedIn = localStorage.getItem("auth");
    if (!isLoggedIn) {
        throw redirect("/login")
    }

    return getCourseDatatable({ id: params.id, limit: 0 });
}

export default function Course() {

    const navigate = useNavigate();
    const request: any = useLoaderData();

    console.log(request);
    const [data, setData] = useState<any>(request);
    const [argomenti, setArgomenti] = useState<any>(data.argomenti);
    const [editable, setEditable] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editList, setEditList] = useState<any>([]);
    const [typeEdit, setTypeEdit] = useState<string>();
    const [indexArgomento, setIndexArgomento] = useState<number>(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    useEffect(() => {
        if (editList) {
            if (typeEdit === "argument") {
                const newArray = reorderObjects(editList, argomenti, "idArgomento")
                setArgomenti(newArray.map(((v: any, index: number) => { v.codiceProgressivo = index; return v })))
            }
            if (typeEdit === "lection") {
                const newArray = reorderObjects(editList, argomenti[indexArgomento].lezioni, "id");
                argomenti[indexArgomento].lezioni = newArray.map(((v: any, index: number) => { v.codiceProgressivo = index; return v }))
                setArgomenti(argomenti)
            }
        }

    }, [editList])

    const reorderObjects = (numArr: Array<number>, objArr: Array<any>, id: string) => {
        // Crea una mappa di ricerca dagli ID degli oggetti
        let objMap = new Map(objArr.map(obj => [obj[id], obj]));

        // Riordina l'array degli oggetti in base all'ordine degli ID nei numeri
        return numArr.map(num => objMap.get(num));
    }

    const onClick = (dataColumn: any) => {
        navigate("/lection/" + dataColumn.data[0])
    }

    const onSave = () => {
        //Save order arguments and lections
        const _data = {
            argomenti
        }
        updateCourseArgumentsLectionsOrder(_data)
    }

    const onEdit = (type: "argument" | "lection", indexArgomento?: number) => {
        if (type === "argument") {
            const listArguments = argomenti.map((argomento: any) => argomento.idArgomento)
            setTypeEdit("argument")
            setEditList(listArguments)
        }
        if (type === "lection" && (indexArgomento as number) > -1) {

            const listArguments = argomenti[indexArgomento as number].lezioni.map((lezione: any) => lezione.id)
            setIndexArgomento(indexArgomento as number)
            setTypeEdit("lection")
            setEditList(listArguments)
        }

        setShowModal(true)
    }

    return (
        <Container>
            <Modal show={showModal} centered onHide={() => setShowModal(prev => !prev)}>
                <Modal.Body>
                    <Reorder.Group axis="y" values={editList} onReorder={setEditList}>
                        {
                            typeEdit === "argument" && argomenti.map((item: any, index: number) => {
                                return (
                                    <Reorder.Item key={item.idArgomento} value={item.idArgomento} as="div" className="d-flex w-100" style={{ justifyContent: "space-between", paddingTop: 20, paddingRight: 20, cursor: "pointer" }}>
                                        <p style={{ marginTop: 2, fontWeight: "500", marginRight: 10 }}>{item.titoloArgomento}</p>
                                        <div style={{ width: 25, height: 25 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" width="25" height="25">
                                                <path d="M 5 0 C 7.761 0 10 2.239 10 5 C 10 7.761 7.761 10 5 10 C 2.239 10 0 7.761 0 5 C 0 2.239 2.239 0 5 0 Z" fill="#CCC"></path>
                                                <path d="M 19 0 C 21.761 0 24 2.239 24 5 C 24 7.761 21.761 10 19 10 C 16.239 10 14 7.761 14 5 C 14 2.239 16.239 0 19 0 Z" fill="#CCC"></path>
                                                <path d="M 33 0 C 35.761 0 38 2.239 38 5 C 38 7.761 35.761 10 33 10 C 30.239 10 28 7.761 28 5 C 28 2.239 30.239 0 33 0 Z" fill="#CCC"></path>
                                                <path d="M 33 14 C 35.761 14 38 16.239 38 19 C 38 21.761 35.761 24 33 24 C 30.239 24 28 21.761 28 19 C 28 16.239 30.239 14 33 14 Z" fill="#CCC"></path>
                                                <path d="M 19 14 C 21.761 14 24 16.239 24 19 C 24 21.761 21.761 24 19 24 C 16.239 24 14 21.761 14 19 C 14 16.239 16.239 14 19 14 Z" fill="#CCC"></path>
                                                <path d="M 5 14 C 7.761 14 10 16.239 10 19 C 10 21.761 7.761 24 5 24 C 2.239 24 0 21.761 0 19 C 0 16.239 2.239 14 5 14 Z" fill="#CCC"></path>
                                                <path d="M 5 28 C 7.761 28 10 30.239 10 33 C 10 35.761 7.761 38 5 38 C 2.239 38 0 35.761 0 33 C 0 30.239 2.239 28 5 28 Z" fill="#CCC"></path>
                                                <path d="M 19 28 C 21.761 28 24 30.239 24 33 C 24 35.761 21.761 38 19 38 C 16.239 38 14 35.761 14 33 C 14 30.239 16.239 28 19 28 Z" fill="#CCC"></path>
                                                <path d="M 33 28 C 35.761 28 38 30.239 38 33 C 38 35.761 35.761 38 33 38 C 30.239 38 28 35.761 28 33 C 28 30.239 30.239 28 33 28 Z" fill="#CCC"></path>
                                            </svg>
                                        </div>
                                    </Reorder.Item>);
                            })
                        }
                        {
                            typeEdit === "lection" && argomenti[indexArgomento].lezioni.map((item: any, index: number) => {
                                return (
                                    <Reorder.Item key={item.id} value={item.id} as="div" className="d-flex w-100" style={{ justifyContent: "space-between", paddingTop: 20, paddingRight: 20, cursor: "pointer" }}>
                                        <p style={{ marginTop: 2, fontWeight: "500", marginRight: 10 }}>{item.titoloLezione}</p>
                                        <div style={{ width: 25, height: 25 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" width="25" height="25">
                                                <path d="M 5 0 C 7.761 0 10 2.239 10 5 C 10 7.761 7.761 10 5 10 C 2.239 10 0 7.761 0 5 C 0 2.239 2.239 0 5 0 Z" fill="#CCC"></path>
                                                <path d="M 19 0 C 21.761 0 24 2.239 24 5 C 24 7.761 21.761 10 19 10 C 16.239 10 14 7.761 14 5 C 14 2.239 16.239 0 19 0 Z" fill="#CCC"></path>
                                                <path d="M 33 0 C 35.761 0 38 2.239 38 5 C 38 7.761 35.761 10 33 10 C 30.239 10 28 7.761 28 5 C 28 2.239 30.239 0 33 0 Z" fill="#CCC"></path>
                                                <path d="M 33 14 C 35.761 14 38 16.239 38 19 C 38 21.761 35.761 24 33 24 C 30.239 24 28 21.761 28 19 C 28 16.239 30.239 14 33 14 Z" fill="#CCC"></path>
                                                <path d="M 19 14 C 21.761 14 24 16.239 24 19 C 24 21.761 21.761 24 19 24 C 16.239 24 14 21.761 14 19 C 14 16.239 16.239 14 19 14 Z" fill="#CCC"></path>
                                                <path d="M 5 14 C 7.761 14 10 16.239 10 19 C 10 21.761 7.761 24 5 24 C 2.239 24 0 21.761 0 19 C 0 16.239 2.239 14 5 14 Z" fill="#CCC"></path>
                                                <path d="M 5 28 C 7.761 28 10 30.239 10 33 C 10 35.761 7.761 38 5 38 C 2.239 38 0 35.761 0 33 C 0 30.239 2.239 28 5 28 Z" fill="#CCC"></path>
                                                <path d="M 19 28 C 21.761 28 24 30.239 24 33 C 24 35.761 21.761 38 19 38 C 16.239 38 14 35.761 14 33 C 14 30.239 16.239 28 19 28 Z" fill="#CCC"></path>
                                                <path d="M 33 28 C 35.761 28 38 30.239 38 33 C 38 35.761 35.761 38 33 38 C 30.239 38 28 35.761 28 33 C 28 30.239 30.239 28 33 28 Z" fill="#CCC"></path>
                                            </svg>
                                        </div>
                                    </Reorder.Item>);
                            })
                        }
                    </Reorder.Group>
                </Modal.Body>
            </Modal>
            <p><a href="/" style={{ textDecoration: "none" }}>Corsi</a></p>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 30 }}>
                <h1>Lezioni</h1>
                <div className="d-flex">
                    <MainButton type="button" text="Modifica Corso" onClick={() => navigate("/course/create?type=edit&id=" + data.id)} style={{ minWidth: 200, height: 40, marginRight: 20, color: Colors.white }} />
                    <MainButton type="button" text="Crea Lezione" onClick={() => navigate("/lection/create/" + data.id)} style={{ maxWidth: 200, height: 40, color: Colors.white }} />
                </div>
            </div>
            <b>Corso</b>
            <p>{data.titoloCorso}</p>
            <b>Descrizione</b>
            <p>{data.descrizioneCorso}</p>
            <b>Argomenti</b>
            <Container className="w-100" style={{ height: 50 }}>
                <MainButton type="button" style={{ width: "10%", minWidth: 100, float: "right" }} text={editable ? "Salva" : "Edita"} onClick={() => {
                    if (editable) onSave();
                    setEditable(prev => !prev)
                }} />
            </Container>
            <Container style={{
                maxWidth: "fit-content",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                {
                    argomenti && argomenti.map((argomento: any, indexArgomento: number) => (
                        <div style={{ minWidth: 800, marginTop: 20, border: "1px solid black", borderRadius: 10, borderColor: Colors.greyLight, padding: "15px 15px" }}>
                            <div style={{ display: "inline-flex" }}><h1 style={{ fontSize: 24 }}>{argomento.titoloArgomento}</h1> {editable ? <span style={{ marginTop: 5, marginLeft: 20, color: Colors.PRIMARY, cursor: "pointer" }} onClick={() => onEdit("argument")}>Sposta</span> : null} </div>
                            {
                                argomento.lezioni.map((lezione: any, index: number) => (
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                        <span style={{ cursor: "pointer", marginLeft: 20 }} key={"lection_" + index} onClick={() => navigate("/lection/" + lezione.id)}>{(index + 1) + ")"} {lezione.titoloLezione}</span>
                                        {editable ? <span style={{ marginLeft: 20, color: Colors.PRIMARY, cursor: "pointer" }} onClick={() => onEdit("lection", indexArgomento)}>Sposta</span> :
                                            <Stack direction="horizontal" gap={3}>
                                                <span>Slide: {lezione.slideMarkdown ? <FontAwesomeIcon icon={fas.faCheck} size="1x" color={Colors.greenMint} style={{ padding: "0 10px" }} /> : <FontAwesomeIcon icon={fas.faX} size="1x" color={Colors.redDarky} style={{ padding: "0 10px" }} />}</span>
                                                <span>Testo: {lezione.testo ? <FontAwesomeIcon icon={fas.faCheck} size="1x" color={Colors.greenMint} style={{ padding: "0 10px" }} /> : <FontAwesomeIcon icon={fas.faX} size="1x" color={Colors.redDarky} style={{ padding: "0 10px" }} />}</span>
                                                <span>Audio: {lezione.fileAudio ? <FontAwesomeIcon icon={fas.faCheck} size="1x" color={Colors.greenMint} style={{ padding: "0 10px" }} /> : <FontAwesomeIcon icon={fas.faX} size="1x" color={Colors.redDarky} style={{ padding: "0 10px" }} />}</span>
                                                <span>Quiz: {lezione.quiz ? <FontAwesomeIcon icon={fas.faCheck} size="1x" color={Colors.greenMint} style={{ padding: "0 10px" }} /> : <FontAwesomeIcon icon={fas.faX} size="1x" color={Colors.redDarky} style={{ padding: "0 10px" }} />}</span>
                                            </Stack>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </Container>
            {
                /*
                <div style={{ marginTop: 30 }}>
                    <TableResponsive 
                        header={["Id", "Argomento", "Lezione", "Slide", "Testo", "Quiz", "Audio", ""]}
                        body={body}
                        onClick={onClick}
                        onEdit={onEdit}
                        onSave={onSave}
                    />
                    <div className="d-flex" style={{ float: "right", flexDirection: "row", maxWidth: 300, alignSelf: "flex-end" }}>
                        <p style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }} ><b>Paginazione</b></p>
                        <MainButton type="button" text="Indietro" disabled={page === 1}  onClick={() => setPage(prev => prev-1)} style={{ color: Colors.white }}/>
                        <p  style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }}><b>{page}</b></p>
                        <MainButton type="button" text="Avanti" onClick={() => setPage(prev => prev+1)} disabled={body.length < 10} style={{ color: Colors.white }}/>
                    </div>
                </div>
                */
            }
        </Container>
    );
}