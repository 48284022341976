const getColorScheme = () => {
    return "light";
}

export const Colors = {
    PRIMARY: "#0B5997",
    PRIMARY_FADED: "#8ae3d4",
    PRIMARY_BG: "#F9F9F9",
    white: getColorScheme() === "light" ? "white" : "#0f0f0f",
    whitePaper: "#F6F6F6",
    black: getColorScheme() === "light" ? "#0f0f0f" : "white",
    blackPipe: '#3E3E3E',
    cyanDeepAqua: '#0088B3',
    greenMint: '#00978D',
    greenForest: '#02514C',
    greyLight: getColorScheme() === "light" ? '#D2D2D2' : "#232D3F",
    grey: '#656565',
    greyText: '#6E6B6B',
    greyInput: getColorScheme() === "light" ? "#ECECEC" : "#0f0f0f",
    red: "#FF4747",
    redDarky: '#750000',
    yellow: "#FFFA77",
    rgba: {
        black: getColorScheme() === "light" ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)"
    },
    input: {
        background: "#EAEAEA"
    }
}