import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar.component";
import { routeArray } from "./App";
import { useEffect, useRef, useState } from "react";
import NavbarHeader from "./components/Navbar.component";
import { Colors } from "./config/colors.config";

export default function Layout() {

    const ref = useRef(null);
    const [height, setHeight] = useState<number>(window.innerHeight);

    useEffect(() => {
        if(ref && ref.current) setHeight((ref.current as any).offsetHeight);
    }, [ref])

    return (
        <>
            <Sidebar routes={routeArray} />
            <NavbarHeader />
            <main ref={ref} style={{ backgroundColor: Colors.PRIMARY_BG, marginLeft: 300, paddingTop: "5%", paddingBottom: window.innerHeight, paddingLeft: "5%", paddingRight: "5%" }}>
                <Outlet />
            </main>
        </>
    );
}