import { Modal } from "react-bootstrap";
import MainButton from "./MainButton.component";
import { Colors } from "../config/colors.config";

export default function ModalDelete({ show, onSubmit, onClose }: { show: boolean, onSubmit: () => void, onClose: () => void}) {
    return(
        <Modal show={show} onHide={onClose} centered>
            <Modal.Body>
                <p style={{ textAlign: "center", fontSize: 22, fontWeight: "700"}}>Sei sicuro di modificare<br />la lezione?</p>
                <p style={{ textAlign: "center" }}>La modifica della descrizione delle lezioni comporta la cancellazione delle slide, del testo della lezione, 
                    dei quiz e del file audio (se presenti) che dovranno essere generate nuovamente</p>
                <MainButton type="button" text="Modifica" onClick={onSubmit} style={{ fontWeight: "600" }}/>
                <p style={{ cursor: "pointer", color: Colors.red, fontWeight: "600", textAlign: "center", marginTop: 10 }} onClick={onClose}>Chiudi</p>
            </Modal.Body>

        </Modal>
    )
}