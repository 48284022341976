import axios from "axios";
import axiosConfig from "../../config/axios.config";

export async function login({ email, password }: { email: string, password: string }): Promise<any> {

    try {
        const url = `${axiosConfig.url}/auth/login`;

        const res = await axios.post(url, {
            eMail: email,
            Password: password
        });

        if (res.status === 500) {
            return {
                message: res.data.message,
                statusText: res.statusText,
                status: res.status
            }
        }

        localStorage.setItem("auth", res.data.data.auth_token);
        return {
            message: "Login con successo",
            statusText: "Login con successo",
            status: 200
        }
    } catch (error) {
        return {
            message: "Login fallito",
            statusText: "Autenticazione fallita",
            status: 500
        }
    }

}

export async function register({ email, password }: { email: string, password: string }) {
    /*
    const url = `/api/course`;

    const res = await fetch(url);

    if(!res.ok) {
        throw {
            message: "Failed to fetch courses",
            statusText: res.statusText,
            status: res.status
        }
    }

    const data = await res.json();
    return data.courses;
    */

    return {
        authToken: "skfnuciafbakjcbajuygfwy"
    }
}