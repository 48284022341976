import { Container } from "react-bootstrap";
import { getCourses } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import MainButton from "../../components/MainButton.component";
import TableResponsive from "../../components/TableResponsive.component";
import { useEffect, useState } from "react";
import { Colors } from "../../config/colors.config";

export function loader({ params }: any) {

    const isLoggedIn = localStorage.getItem("auth");
    if (!isLoggedIn) {
        throw redirect("/login")
    }
    return getCourses({ limit: 0 });

}

export default function Courses() {

    const navigate = useNavigate();
    const request: any = useLoaderData();

    const [data, setData] = useState<any>(request);
    const [page, setPage] = useState<number>(1);
    const [disabledAvanti, setDisabledAvanti] = useState<boolean>(false);

    useEffect(() => {
        getCourses({ limit: page === 1 ? 0 : ((page * 10) - 10) })
            .then(_data => { if (data != _data) { setData(_data) } else { setDisabledAvanti(true) } });
    }, [page])

    const onClick = (dataColumn: any) => {
        console.log("dataColumn", dataColumn)
        navigate("/course/" + dataColumn.data[0])
    }
    return (
        <Container>
            <h1>Riepilogo Corsi</h1>
            <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 30 }}>
                <MainButton type="button" text="Crea Corso" onClick={() => navigate("/course/create")} style={{ maxWidth: 200, color: Colors.white }} />
            </div>
            <div style={{ marginTop: 30 }}>
                <TableResponsive
                    header={["Id", "Codice corso", "Titolo", "Numero lezioni", "Lezioni completati", "Creato in data", ""]}
                    body={data}
                    onClick={onClick}
                />
                <div className="d-flex" style={{ float: "right", flexDirection: "row", maxWidth: 300, alignSelf: "flex-end" }}>
                    <p style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }} ><b>Paginazione</b></p>
                    <MainButton type="button" text="Indietro" disabled={page === 1} onClick={() => setPage(prev => prev - 1)} style={{ color: Colors.white }} />
                    <p style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }}><b>{page}</b></p>
                    <MainButton type="button" text="Avanti" onClick={() => setPage(prev => prev + 1)} disabled={data.length < 10 || disabledAvanti} style={{ color: Colors.white }} />
                </div>
            </div>

        </Container>
    );
}