import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Table } from "react-bootstrap";

export type TableResponsive = {
    header: Array<string>
    body: Array<Array<string | number | React.ReactElement>>
    onClick?: (dataColumn: any) => void
    onEdit?: (dataColumn: any) => void
    onSave?: (dataColumn: any, form: any) => void
}

function TableResponsive({ header, body, onClick, onEdit, onSave }: TableResponsive) {

    const [form, setForm] = useState<any>({});

    const onChange = (e: any) => {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value,
      })
    }

    return (
      <Table responsive striped hover style={{ width: "100%" }}>
        <thead>
          <tr>
            {header.map((header, index) => {
              if(!index) return;
              return (
              <th key={index}>
                {header}
                {" "}
                </th>
            )})}
            
          </tr>
        </thead>
        <tbody>
            {
                body.map((columns: any, index_columns: number) => {
                    return (
                    <tr key={"_tr" + index_columns} onClick={(columns.clickable && onClick) ? () => onClick(columns) : () => {}} style={{ cursor: columns.clickable ? "pointer" : "auto" }}>
                        {columns.data.map((column: any, index: number) => {
                            if(!index) return;

                            if(columns.editable === "editing" && onSave && (index === columns.data.length - 1)) {
                              return (
                                  <td key={"_td" + columns.mapperIds[index] + index_columns} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <div style={{ display: "flex", flexDirection: "row-reverse"}}>
                                        <button onClick={() => {
                                          onSave({...columns, rowIndex: index_columns }, form)
                                        }} style={{ borderRadius: 10, fontSize: 14, paddingLeft: 15, paddingRight: 15, backgroundColor: "#D9D9D9", border: 0 }}>Salva</button>
                                    </div> 
                                  </td>
                              )
                            }

                            if(columns.editable === "idle" && onEdit && (index === columns.data.length - 1)) {
                              return( 
                                <td key={"_td" + columns.mapperIds[index] + index_columns} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                  <div style={{ display: "flex", flexDirection: "row-reverse"}}>
                                      <FontAwesomeIcon icon={fas.faPen} size="1x" style={{ padding: "5px 10px", cursor: "pointer" }} onClick={() => onEdit({...columns, rowIndex: index_columns })}/>
                                  </div>
                                </td>
                              );
                            }
                            
                            return (
                                <td key={index} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                  { column && columns.editable === "editing" && (index !== columns.data.length - 1) ? 
                                  <input defaultValue={column} style={{
                                    backgroundColor: "#D9D9D9", border: 0, borderRadius: 10, paddingLeft: 15, paddingRight: 15, 
                                  }} name={columns.mapperIds[index]} onChange={onChange} /> : column}
                                </td>
                            )}
                        )}
                    </tr>
                    );
                })
            }
        </tbody>
      </Table>
    );
  }
  
  export default TableResponsive;