import moment from "moment";
import axiosConfig from "../../config/axios.config";
import axios from "axios";
import { getImage } from "../image/Image.api";

export async function getSlide(id: string) {

    const url = `${axiosConfig.url}/Slide/${id}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data.data
}

export async function createSlide(data: {
    fkCorso: number
    fkArgomento: number,
    titoloLezione: string,
    descrizioneLezione: string,
    minimoSlide: number,
    massimoSlide: number
    righeSlide: number
}) {

    try {
        const url = `${axiosConfig.url}/Slide`;


        const res = await axios.post(url, data, {
            headers: {
                Authorization: axiosConfig.token()
            }
        });

        //console.log(res)
        return res.data.data
    } catch (error) {
        throw error;
    }

}

export async function updateSlide(data: {
    idSlide: number
    testoAssociato: string
}) {
    const url = `${axiosConfig.url}/slide`;


    const res = await axios.put(url, data, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    return res.data.data
}


export async function updateSlideOnly(data: {
    idLezione: number
    fkArgomento?: number
    codiceProgressivo?: number
    descrizioneLezione?: string
    slideMarkdown?: string
    testo?: string
    quiz?: string
    fileAudio?: string
    minimoSlide?: number
    massimoSlide?: number
    righeSlide?: number
}) {
    const url = `${axiosConfig.url}/Slide/only`;


    const res = await axios.put(url, data, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    return res.data.data
}


export async function generateTextSlide(idLezione: number) {

    const url = `${axiosConfig.url}/text/generate`;


    const res = await axios.post(url, {
        idLezione
    }, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    console.log(res.data);
    return res.data.data
}

export async function generateQuizSlide({ id, totaleDomande }: { id: string, totaleDomande: number }) {

    const url = `${axiosConfig.url}/quiz/generate`;


    const res = await axios.post(url, {
        idLezione: id,
        totaleDomande
    }, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    console.log(res.data);
    return res.data.data
}

export async function generateAudioSlide(idLezione: number) {

    const url = `${axiosConfig.url}/audio/generate`;


    const res = await axios.post(url, {
        idLezione
    }, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });


    if (res.status !== 200) {
        return {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    console.log(res.data);
    return res.data.data
}

export async function updateSlidesProgressiveCode(data: Array<{
    fkLezione: number,
    idSlide: number,
    codiceProgressivo: number
}>) {
    const url = `${axiosConfig.url}/slides/progressive`;


    const res = await axios.put(url, data, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    return res.data.data
}

export async function downloadSlide(id: string) {

    const url = `${axiosConfig.url}/download/Slide/${id}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if (res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return await getImage(process.env.REACT_APP_API_URL + res.data.data.filePath)
}

