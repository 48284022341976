import { act, useCallback, useEffect, useState } from "react";
import { Button, Carousel, Container, Form, Modal } from "react-bootstrap";
import { Colors } from "../config/colors.config";
import MainButton from "./MainButton.component";
import FormInput from "./FormInput.component";
import { updateSlide } from "../api/Index";
import { getAudio } from "../api/audio/Audio.api";
import axiosConfig from "../config/axios.config";

type Slide = {
    placeholder: string
    title: string
    bulletPoints: Array<string>
    metadata: any
}

function Slide({ status = "idle", show, data: inputData, activeIndex, handleClose, handleSave }: { status: string, show: boolean, data: Array<Slide>, activeIndex: number, handleClose: () => void, handleSave: (form: any) => void }) {
    const [data, setData] = useState<any>(inputData);
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [slides, setSlides] = useState<Array<any>>([]);
    const [renderText, setRenderText] = useState<any>("");
    const [edit, setEdit] = useState<string>(status);
    const [showtext, setShowText] = useState<boolean>(false);
    const [audio, setAudio] = useState<any>(null);
    const [errMsg, setErrMsg] = useState<any>("");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (edit === "editing") {
            console.log("submitting...")
            setError("");
            setLoadingBtn(true);
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.stopPropagation();
                setLoadingBtn(false);
            }

            if (showtext) {
                const testoAssociato = form[`testoAssociato_${activeIndex}`].value;
                console.log(testoAssociato)
                if (testoAssociato?.length > 0) {
                    const req = {
                        idSlide: data[activeIndex].metadata.idSlide,
                        testoAssociato: testoAssociato
                    }

                    setValidated(true);

                    updateSlide(req)
                        .then(() => {
                            setLoadingBtn(false);
                            setEdit("idle");
                            setData(data.map((slide: any) => {
                                if (slide.metadata.idSlide === data[activeIndex].metadata.idSlide) {
                                    return { ...slide, metadata: { ...slide.metadata, testoAssociato: testoAssociato } }
                                }

                                return slide
                            }))
                        })
                        .catch((err) => { alert("Aggiornamento fallito"); setLoadingBtn(false) })
                } else {
                    setLoadingBtn(false);
                    setErrMsg("Non e possibile lasciare vuoto il campo, se si vuole eliminare usare la cancellazione.")
                }

            } else {
                const title = form[`title_${activeIndex}`] ? form[`title_${activeIndex}`].value : ""
                const bullet1 = form[`bullet_${activeIndex}_0`] ? form[`bullet_${activeIndex}_0`].value : ""
                const bullet2 = form[`bullet_${activeIndex}_1`] ? form[`bullet_${activeIndex}_1`].value : ""
                const bullet3 = form[`bullet_${activeIndex}_2`] ? form[`bullet_${activeIndex}_2`].value : ""

                if (title?.length > 0 && bullet1?.length > 0 && bullet2?.length > 0 && bullet2?.length > 0) {
                    const data = {
                        title: title,
                        bullets: [bullet1, bullet2, bullet3]
                    }

                    setEdit("idle");
                    handleSave(data);
                    handleClose();
                } else {
                    setLoadingBtn(false);
                    setErrMsg("Non e possibile lasciare vuoto il campo, se si vuole eliminare usare la cancellazione.")
                }

            }

        }

    }

    useEffect(() => { setEdit(status); setLoadingBtn(false); setErrMsg("") }, [status])

    useEffect(() => {
        let render = [];
        for (let slideIndex = 0; slideIndex < data.length; slideIndex++) {
            const slide = data[slideIndex];
            console.log("Slide", slide)
            render.push(
                <Carousel.Item key={slideIndex}>
                    <img className="d-block w-100" src={require("../assets/white_bg.PNG")} alt="First slide" style={{ minHeight: 450 }} />
                    <Carousel.Caption style={{ textAlign: "left", width: "100%", margin: 0, left: 0, top: 50, paddingTop: "5", paddingBottom: "5%", paddingLeft: "5%", paddingRight: "5%" }}>
                        <div style={{ backgroundColor: Colors.white, width: "100%" }}>
                            {
                                showtext ?
                                    <>
                                        {
                                            edit === "idle" ?
                                                <div style={{ maxHeight: 400, overflowY: "scroll", paddingBottom: 50 }}>
                                                    <p style={{ color: Colors.black }}>{slide.metadata.testoAssociato}</p>
                                                </div>

                                                :
                                                <>
                                                    {errMsg && <p style={{ color: Colors.redDarky }}>{errMsg}</p>}
                                                    <FormInput
                                                        id={`testoAssociato_${slideIndex}`}
                                                        type="textarea"
                                                        placeholder="Descrizione"
                                                        style={{ height: 300 }}
                                                        required={true}
                                                        defaultValue={slide.metadata.testoAssociato}
                                                    />
                                                </>

                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            edit === "idle" ?
                                                <>
                                                    <b><h1 style={{ color: Colors.black, fontSize: 24 }}>{slide.title}</h1></b>
                                                    <ul>
                                                        {slide.bulletPoints.map((v: string) => {
                                                            var tempElement = document.createElement("div");
                                                            tempElement.innerHTML = v;
                                                            return (<li style={{ color: Colors.black }}>
                                                                <p style={{ color: Colors.black, fontSize: 19 }}>{tempElement.innerHTML || tempElement.textContent}</p>
                                                            </li>);
                                                        })}
                                                    </ul>
                                                </>
                                                :
                                                <div style={{ height: 450, overflowY: "scroll", overflowX: "hidden", paddingRight: 20, paddingBottom: 30 }}>
                                                    {errMsg && <p style={{ color: Colors.redDarky }}>{errMsg}</p>}
                                                    <FormInput id={`title_${slideIndex}`} type="text" placeholder="Titolo" defaultValue={slide.title} required={true} style={{
                                                        fontSize: 24,
                                                        fontWeight: "600",
                                                        width: "100%",
                                                        backgroundColor: "#D9D9D9", border: 0, borderRadius: 10, paddingLeft: 15, paddingRight: 15,
                                                    }} />

                                                    {slide.bulletPoints.map((v: string, i: number) => {
                                                        var tempElement = document.createElement("div");
                                                        tempElement.innerHTML = v;
                                                        return (
                                                            <FormInput id={`bullet_${slideIndex}_${i}`} type="textarea" placeholder="Bullet" defaultValue={tempElement.innerHTML} required={true} style={{
                                                                marginTop: 10,
                                                                fontSize: 19,
                                                                width: "100%",
                                                                backgroundColor: "#D9D9D9", border: 0, borderRadius: 10, paddingLeft: 15, paddingRight: 15,
                                                            }} />
                                                        );

                                                    })}
                                                    <div style={{ height: 50, marginBottom: 30 }} />
                                                </div>
                                        }
                                    </>
                            }


                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            );
        }

        setSlides(render)

    }, [edit, data, showtext, activeIndex, errMsg]);

    /*
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, { passive: true });
 
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
     }, [])

    const handleKeyDown = (e: any) => {
        const key = e.key;
    
        if(key === "ArrowRight") {
            activeIndex++
        }

        if(key === "ArrowLeft") {
            activeIndex--
        }
   };
   */

    const playAudio = async (url: string) => {
        if (!audio) {
            const audio = new Audio(`${axiosConfig.url}${url}`);
            setAudio(audio);
            audio.play()
        } else {
            audio.play()
        }
    }

    const pauseAudio = () => {
        if (audio) {
            audio.pause();
        }
    };


    const stopAudio = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0; // Reset to the start
        }
    };

    console.log(loadingBtn)
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
                setEdit("idle")
                setLoadingBtn(false)
                setErrMsg("")
                stopAudio()
                setAudio(null)
                handleClose();
            }}
            style={{ marginTop: -100 }}
            contentClassName="slide"
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header style={{ border: 0 }}>
                    <div className="w-100 d-flex" style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                        {
                            edit === "editing" ?
                                <MainButton type="submit" text="Salva" loading={loadingBtn} style={{
                                    backgroundColor: Colors.PRIMARY,
                                    color: Colors.white,
                                    borderColor: Colors.PRIMARY,
                                    width: "100%",
                                    fontSize: 16,
                                    fontWeight: "600",
                                    borderRadius: 10,
                                    maxWidth: 200
                                }} />
                                :
                                <>
                                    <MainButton type="button" text={"Modifica"} onClick={() => setEdit("editing")} style={{ maxWidth: 200 }} />
                                </>

                        }

                        <MainButton type="button" text={"Annulla"} onClick={() => {
                            setEdit("idle")
                            setLoadingBtn(false)
                            setErrMsg("")
                            stopAudio()
                            setAudio(null)
                            handleClose();
                        }} style={{ maxWidth: 200, backgroundColor: Colors.red, border: 0 }} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "transparent" }}>
                    <Carousel indicators={false} controls={false} slide={false} activeIndex={activeIndex}>
                        {slides}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer style={{ border: 0 }}>
                    {inputData[activeIndex].metadata.url_audio ? <>
                        <MainButton type="button" text={"Play"} onClick={() => playAudio(inputData[activeIndex].metadata.url_audio)} style={{ maxWidth: 200 }} />
                        <MainButton type="button" text={"Pause"} onClick={() => pauseAudio()} style={{ maxWidth: 200 }} />
                        <MainButton type="button" text={"Stop"} onClick={() => stopAudio()} style={{ maxWidth: 200 }} />
                    </> : null}
                    {inputData[activeIndex].metadata.testoAssociato ?
                        <MainButton type="button" text={showtext ? "Mostra bullet" : "Mostra testo"} onClick={() => setShowText(!showtext)} style={{ maxWidth: 200 }} />
                        : null}

                </Modal.Footer>
            </Form>

        </Modal>

    );
}

export default Slide;