import axiosConfig from "../../config/axios.config";
import axios from "axios";

export async function getArguments(idCorso: string) {

    const url = `${axiosConfig.url}/arguments/${idCorso}`;

    const res = await axios.get(url, {
        headers: {
            Authorization: axiosConfig.token()
        }
    });

    if(res.status !== 200) {
        throw {
            message: res.data.message,
            statusText: res.statusText,
            status: res.status
        }
    }

    return res.data.data
}
