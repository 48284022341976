
import { useLocation, NavLink } from "react-router-dom";

import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../config/colors.config";
import Logo from "./Logo.component";
import { RootRoutesType } from "../routes/Routes";

function Sidebar({ routes }: any) {
  const location = useLocation();
  const activeRoute = (route: RootRoutesType) => {
    return (location.pathname === route.path) ? "bg_active" : "";
  };

  return (
    <div style={{
        maxWidth: 300,
        width: "100%",
        height: "100%",
        minWidth: 100,
        position: "fixed",
        top: 0,
        left: 0
    }}>
      <div style={{ backgroundColor: "#fff" }}>
        <Logo />
      </div>
        <Nav defaultActiveKey="/" className="flex-column" style={{ marginTop: 20 }}>
          {routes.map((prop: RootRoutesType, key: number) => {
              const _activeRoute = activeRoute(prop);
              return (
                <div style={{
                    padding: "10px 15px",
                    borderRadius: 8,
                    margin: "5px 20px"
                }}
                  className={_activeRoute ? "bg_active" : ""}
                  key={key}
                >
                  <NavLink
                    to={prop.path}
                    style={{ width: "100%", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon icon={prop.icon} color={_activeRoute ? Colors.white : Colors.greyLight}/>
                    <span style={{ marginLeft: 10, color:  _activeRoute ? Colors.white:  Colors.greyLight}}>{prop.name}</span>
                  </NavLink>
                </div>
              );
          })}
        </Nav>
    </div>
  );
}

export default Sidebar;
