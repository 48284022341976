import { createBrowserRouter, createRoutesFromElements, redirect, Route, RouterProvider } from 'react-router-dom';
import './App.css';
import { Spinner } from 'react-bootstrap';
import Layout from './Layout';
import AuthRequired from './AuthRequired';
import Login, { loader as loginLoader } from './pages/login/Login';
import RootRoutes from './routes/Routes';
import Courses, { loader as coursesLoader } from './pages/courses/Courses';
import CodeGeneration, { loader as codeGenerationLoader } from './pages/codeGeneration/CodeGeneration';
import CourseCreate, { loader as createCourseLoader } from './pages/courseCreate/courseCreate';
import Course, { loader as courseLoader } from './pages/lection/Course';
import LectionCreate, { loader as createLectionLoader } from './pages/lectionCreate/lectionCreate';
import Lection, { loader as lectionLoader } from './pages/lection/Lection';
import TextCreate, { loader as createTextlectionLoader } from './pages/textCreate/textCreate';
import QuizCreate, { loader as createQuizlectionLoader } from './pages/quizCreate/quizCreate';
import ErrorPage from './Error';

var reactRouterToArray = require('react-router-to-array');

const routes =
  <Route>
    <Route element={<AuthRequired />}>
      <Route path="/" element={<Layout />} >
        <Route
          index
          element={<Courses />}
          loader={coursesLoader}
        />
        <Route
          path="codeGeneration"
          element={<CodeGeneration />}
          loader={codeGenerationLoader}
        />
        <Route
          path="course/create"
          element={<CourseCreate />}
          loader={createCourseLoader}
        />
        <Route
          path="course/:id"
          element={<Course />}
          loader={courseLoader}
        />
        <Route
          path="lection/create/:id"
          element={<LectionCreate />}
          loader={createLectionLoader}
        />
        <Route
          path="lection/:id"
          element={<Lection />}
          loader={lectionLoader}
        />
        <Route
          path="text/create/:id"
          element={<TextCreate />}
          loader={createTextlectionLoader}
        />
        <Route
          path="quiz/create/:id"
          element={<QuizCreate />}
          loader={createQuizlectionLoader}
        />
      </Route>
    </Route>
    <Route path="login" element={<Login />} errorElement={<ErrorPage />} loader={loginLoader} />
    <Route element={<ErrorPage />} />
  </Route>;



export const routeArray = RootRoutes.filter((route: any, index: number) => {
  const _route = reactRouterToArray(routes).find((r: any) => r === route.path);
  if (_route) {
    return routes;
  }
});

const router = createBrowserRouter(createRoutesFromElements(routes));

function App() {
  return (
    <RouterProvider router={router} fallbackElement={<Loader />} />
  );
}

export function Loader() {
  return (
    <div className='d-flex' style={{ position: "absolute", top: "50%", left: "50%" }}>
      <Spinner animation="border" />
    </div>
  )
}

export default App;
