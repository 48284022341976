import { useRef, useState } from "react";
import { Alert, Col, Container, Form, Row, } from "react-bootstrap";
import FormInput from "../../components/FormInput.component";
import MainButton from "../../components/MainButton.component";
import { createCourses, getCourse, updateCourses } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

export async function loader({ request }: any) {
  const isLoggedIn = localStorage.getItem("auth");
  if (!isLoggedIn) {
    throw redirect("/login")
  }

  const url = new URL(request.url);
  const type = url.searchParams.get("type");
  const id = url.searchParams.get("id");

  if (id) {
    const course = await getCourse({ id });

    return {
      type,
      id,
      ...course,
    };
  }

  return null;
}

export default function CreateCourse() {

  const request: any = useLoaderData();

  const [data, setData] = useState<any>(request);

  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const [argumentsArray, setArgumentsArray] = useState<Array<any>>(data ? data.argomenti : [{
    codiceProgressivo: "",
    titoloArgomento: ""
  }]);

  const [fileAudio, setFileAudio] = useState<any>("Carica file audio");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
      setValidated(false);
      return;
    }

    const body = {
      idCorso: data?.idCorso,
      codiceCorso: form.codiceCorso.value,
      titoloCorso: form.title.value,
      descrizione: form.description.value,
      audio: form.audio.files,
      voceAutore: data?.voceAutore,
      status: data?.status,
      argomenti: argumentsArray.filter((v) => v.codiceProgressivo && v.titoloArgomento)
    }

    setValidated(true);

    if (data?.type === "edit") {
      updateCourses(body)
        .then(() => {
          navigate("/course/" + data.id);
        })
        .catch((err) => { alert("Aggiornamento fallito"); setLoading(false) })
    } else {
      createCourses(body)
        .then(({ data }: any) => {
          navigate("/course/" + data.id);
        })
        .catch((err) => { alert("Creazione fallita"); setLoading(false) })
    }


  };

  const onAdd = () => {
    setArgumentsArray(oldArray => [...oldArray, {
      codiceProgressivo: "",
      titoloArgomento: ""
    }])
  }

  const onRemove = (currentIndex: number) => {
    const newArray = argumentsArray.filter((v, i) => i !== currentIndex);
    setArgumentsArray(newArray)
  }

  const onChange = (index: number, name: string, data: any) => {
    argumentsArray[index] = {
      ...argumentsArray[index],
      [name]: data
    }

    setArgumentsArray(prev => [...argumentsArray])
  }

  const handleFile = (e: any) => {
    if (!e.target.value) { setFileAudio("Carica file audio"); return; }
    setFileAudio(e.target.value.replace(/.*[\/\\]/, ''));
  };


  console.log(data);
  return (
    <Container style={{
      maxWidth: "80%",
      justifyContent: "center",
      marginTop: 50
    }}>
      <Container className="text-center">
        <h1>{data?.type === "edit" ? "Modifica" : "Crea"} Corso</h1>
        <p>
          Comincia a creare le slide personalizzati per ogni argomento che vuoi esporre ai tuoi studenti.
        </p>
      </Container>

      {error && <Alert variant="danger">{error}</Alert>}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormInput id="title" type="text" placeholder="Titolo" required={true} defaultValue={data?.titoloCorso} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput id="codiceCorso" type="text" placeholder="Codice Corso" required={true} defaultValue={data?.codiceCorso} />
          </Col>
        </Row>
        <FormInput
          id="audio"
          type="file"
          placeholder={fileAudio}
          required={false}
          onChange={handleFile}
          accept=".mp3, .wav, audio/*"
        />
        <p>File .mp3 e .wav</p>
        {data?.voceAutore ? <p>Audio: {data?.voceAutore}</p> : null}
        <FormInput id="description" type="textarea" placeholder="Descrizione" required={true} defaultValue={data?.descrizione} style={{ minHeight: 300 }} />
        {
          argumentsArray.map((argument: any, i: number) => (
            <Row>
              <Col lg={5}>
                <FormInput
                  id={"codiceProgressivo"}
                  type="number"
                  placeholder="Codice progressivo"
                  required={i === 0}
                  value={argument.codiceProgressivo}
                  onChange={(e: any) => onChange(i, "codiceProgressivo", e.target.value)}
                />
              </Col>
              <Col lg={6}>
                <FormInput
                  id={"titoloArgomento"}
                  type="text"
                  placeholder="Argomento"
                  required={i === 0}
                  value={argument.titoloArgomento}
                  onChange={(e: any) => onChange(i, "titoloArgomento", e.target.value)}
                />
              </Col>
              <Col>
                {
                  i === argumentsArray.length - 1 ?
                    <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }} onClick={onAdd}>
                      <FontAwesomeIcon icon={fas.faPlus} style={{ paddingTop: 10 }} />
                    </div> :
                    <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }} onClick={() => onRemove(i)}>
                      <FontAwesomeIcon icon={fas.faMinus} style={{ paddingTop: 10 }} />
                    </div>
                }
              </Col>
            </Row>
          ))
        }
        <MainButton type="submit" text={data?.type === "edit" ? "Salva" : "Crea"} loading={loading} />
      </Form>
    </Container>

  );
}