import { Container } from "react-bootstrap";
import { generateAudioLection, getCodeGeneration } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import TableResponsive from "../../components/TableResponsive.component";
import MainButton from "../../components/MainButton.component";
import { useEffect, useState } from "react";

export function loader({ request }: any) {
    const isLoggedIn = localStorage.getItem("auth");
    if (!isLoggedIn) {
        throw redirect("/login")
    }

    const url = new URL(request.url);
    const id: string = url.searchParams.get("id") || "";
    if (id) {
        generateAudioLection(parseInt(id));
    }

    return getCodeGeneration({ limit: 0 })
}

export default function CodeGeneration() {

    const navigate = useNavigate();
    const request: any = useLoaderData();

    const [data, setData] = useState<any>(request);
    const [page, setPage] = useState<number>(1);
    const [disabledAvanti, setDisabledAvanti] = useState<boolean>(false);

    useEffect(() => {
        navigate('/codeGeneration', { replace: true });
    }, [])

    useEffect(() => {
        getCodeGeneration({ limit: page === 1 ? 0 : ((page * 10) - 10) })
            .then(_data => { if (data != _data) { setData(_data) } else { setDisabledAvanti(true) } });
    }, [page])

    const onClick = (dataColumn: any) => {
        navigate("/lection/" + dataColumn.data[0])
    }

    return (
        <Container>
            <h1>Code Generazione</h1>
            <div style={{ marginTop: 30 }}>
                <TableResponsive
                    header={["Id", "Corso", "Lezione", "Data/Ora richiesta", "Stato di avanzamento", "Ultimo aggiornamento", ""]}
                    body={data}
                    onClick={onClick}
                />
                <div className="d-flex" style={{ float: "right", flexDirection: "row", maxWidth: 300, alignSelf: "flex-end" }}>
                    <p style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }} ><b>Paginazione</b></p>
                    <MainButton type="button" text="Indietro" disabled={page === 1} onClick={() => setPage(prev => prev - 1)} />
                    <p style={{ marginTop: 12, marginLeft: 10, marginRight: 10 }}><b>{page}</b></p>
                    <MainButton type="button" text="Avanti" onClick={() => setPage(prev => prev + 1)} disabled={data.length < 10 || disabledAvanti} />
                </div>
            </div>
        </Container>
    );
}