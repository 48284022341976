import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Colors } from '../config/colors.config';

const Logo = () => {
    return (
            <div style={styles.svgContainer}>
                <img src="/assets/logo/gen-ai.svg" style={styles.imageBrand} />
            </div>
    );
}

interface StyleSheet {
    [key: string]: React.CSSProperties | undefined
 }

const styles: StyleSheet = {
    svgContainer: {
        height: 120,
        textAlign: "center"
       },
    imageBrand: { width: "100%", height: 120, objectFit: "contain", filter: "black" },
    titleBrand: { fontSize: 24, fontWeight: "bold", color: Colors.white, marginLeft: 10 }
}

export default Logo;