import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../config/colors.config";
import { useEffect, useRef, useState } from "react";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import { Col } from "react-bootstrap";
import { getImage } from "../api/image/Image.api";

export default function SlideItem({
    index,
    data,
    onClick,
    onEdit,
    onDelete,
    metaData
}:
    {
        index: number,
        data: {
            url: string
            slide_e64: string
        }
        onClick: (index: number) => void,
        onEdit: (index: number) => void,
        onDelete: (index: number) => void
        metaData?: any
    }) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [currentSize, setCurrentSize] = useState<string>("lg");
    const [imageUrl, setImageUrl] = useState('');

    const size: any = {
        lg: {
            col: "col-sm-12 col-md-12 col-lg-4",
            imgFit: "cover"
        },
        md: {
            col: "col-sm-12 col-md-12 col-lg-6",
            imgFit: "cover"
        },
        sm: {
            col: "col-sm-12 col-md-12 col-lg-12",
            imgFit: "fill"
        }
    }

    useEffect(() => {
        fetchData()
        const handleResize = () => {
            let scaleFactor = "lg"; // Adjust 1000 as needed
            if (window.innerWidth < 1731) scaleFactor = "md";
            if (window.innerWidth < 1278) scaleFactor = "sm";
            setCurrentSize(scaleFactor)
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial call to set column size
        handleResize();

        // Cleanup function
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data]);

    const fetchData = async () => {
        if (data.url) {
            //const imageObjectURL = await getImage(process.env.REACT_APP_API_URL + data.url)
            //setImageUrl(imageObjectURL)
            setImageUrl(`data:image/png;base64,${data.slide_e64}`)
        }

    };

    return (
        <Col className={size[currentSize].col}>
            <div
                style={{ height: 240, marginTop: 50, transform: `scale(${scaleFactor})` }}
            >
                <div className="d-flex" style={{ flexDirection: "row", position: "absolute", right: 2, top: 5 }}>
                    <div style={{ backgroundColor: Colors.PRIMARY, borderRadius: "50%", width: 27, height: 24, paddingBottom: 27, textAlign: "center" }}>
                        <span style={{ fontSize: 12, fontWeight: "700", marginBottom: 10, color: Colors.white }}>{index}</span>
                    </div>
                </div>
                <img src={imageUrl} style={{ width: "100%", height: 240, cursor: "pointer", objectFit: size[currentSize].imgFit }} onClick={() => onClick(index)} />
                <div className="d-flex" style={{ flexDirection: "row", position: "absolute", right: 2, bottom: 5 }}>
                    <div
                        style={{ backgroundColor: Colors.red, borderRadius: "50%", paddingTop: 5, paddingBottom: 5, paddingLeft: 11, paddingRight: 11, cursor: "pointer" }}
                        onClick={() => onDelete(index)}
                    >
                        <FontAwesomeIcon size="1x" icon={fas.faTrash} style={{ width: 10 }} color={Colors.white} />
                    </div>
                    <div
                        style={{ backgroundColor: Colors.PRIMARY, borderRadius: "50%", paddingTop: 5, paddingBottom: 5, paddingLeft: 11, paddingRight: 11, marginLeft: 10, cursor: "pointer" }}
                        onClick={() => onEdit(index)}
                    >
                        <FontAwesomeIcon size="1x" icon={fas.faPen} style={{ width: 10 }} color={Colors.white} />
                    </div>
                </div>
            </div>
        </Col>
    );
}