import { fas, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type RootRoutesType = {
    path: string,
    name: string,
    icon: IconDefinition
}
const RootRoutes: Array<RootRoutesType> = [
    {
      path: "/",
      name: "Corsi",
      icon: fas.faHouse
    },
    {
      path: "/codeGeneration",
      name: "Coda Generazione",
      icon: fas.faList
    }
];

export default RootRoutes