import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Colors } from '../config/colors.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

const NavbarHeader = () => {

    return (
        <Navbar className="Navbar" style={styles.navbar}>
            <Nav className="justify-content-end flex-grow-1 pe-3">
                <NavDropdown title={<FontAwesomeIcon icon={fas.faUser} color={Colors.PRIMARY} size="1x" style={{ width: 25, height: 25 }}/>} id="navbarScrollingDropdown" menuRole="menu">
                    <NavDropdown.Item href="#action4">
                        Quick Reference
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                        Cambio password
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/login">
                        <span style={{ color: Colors.red }}>Logout</span>
                    </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/" style={styles.text}>
                    
                </Nav.Link>
            </Nav>
        </Navbar>
    );
}

interface StyleSheet {
    [key: string]: React.CSSProperties | undefined
}

const styles: StyleSheet = {
    navbar: {
        width: "100%",
        zIndex: 100
    },
    container: { minWidth: "100%", borderRadius: 15, height: 120 },
    containerBrand: { justifyContent: "center", flexDirection: "row" },
    svgBrand: { width: 50, height: 50, flexDirection: "column", justifyContent: "center", alignSelf: "center", marginBottom: 8 },
    imageBrand: { width: 50, height: 50, flexDirection: "column", justifyContent: "center", alignSelf: "center" },
    titleBrand: { fontSize: 24, fontWeight: "bold", color: Colors.white, marginLeft: 10 },
    nav: { flexDirection: "row", justifyContent: "space-between" },
    navItem: { paddingLeft: "10vw", alignItems: "center", flexDirection: "column" },
    text: { fontSize: 19, color: Colors.white, paddingLeft: "5vw", paddingRight: "5vw" }
}

export default NavbarHeader;