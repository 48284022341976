import { useState } from "react";
import { Alert, Col, Container, Form, Row, } from "react-bootstrap";
import FormInput from "../../components/FormInput.component";
import MainButton from "../../components/MainButton.component";
import { createLection, getArguments, getCourse, getLection, updateLection } from "../../api/Index";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { Colors } from "../../config/colors.config";

export async function loader({ params, request }: any) {
  const isLoggedIn = localStorage.getItem("auth");
  if (!isLoggedIn) {
    throw redirect("/login")
  }

  console.log(params, request.url)
  const url = new URL(request.url);
  const type = url.searchParams.get("type");
  const id = url.searchParams.get("id");

  let lection: any = {}
  let _arguments = []
  let course = {}
  if (id && type === "edit") {
    lection = await getLection(id)
    _arguments = await getArguments(lection.fkCorso)
  } else {
    course = await getCourse({ id: params.id })
  }

  console.log("course", course)
  return {
    type,
    idCorso: params.id,
    fkCorso: params.id,
    argomenti: _arguments,
    ...course,
    ...lection,

  };
}

export default function CreateLection() {
  const navigate = useNavigate();
  const data: any = useLoaderData();

  console.log("CreateLection", data);

  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [descriptionLength, setDescriptionLength] = useState<number>(data.type === "edit" ? data.descrizioneLezione.length : 0);


  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    }

    const body = {
      ...data,
      idLezione: data.idLezione,
      fkCorso: parseInt(data.fkCorso),
      fkArgomento: parseInt(form.argomento.value),
      titoloLezione: form.titoloLezione.value,
      descrizioneLezione: form.descrizioneLezione.value,
      codiceProgressivo: data.codiceProgressivoLezione,
      minimoSlide: 1,
      massimoSlide: 3,
      righeSlide: 3
    }

    setValidated(true);

    if (data.type === "edit") {
      updateLection(body)
        .then(({ idLezione }: any) => navigate("/lection/" + idLezione))
        .catch((err) => { alert("Creazione fallita"); setLoading(false) })
    } else {
      createLection(body)
        .then(({ idLezione }: any) => navigate("/lection/" + idLezione))
        .catch((err) => { alert("Creazione fallita"); setLoading(false) })
    }
  };

  const onChangeDescription = (e: any) => {
    setDescriptionLength(e.target.value.length);
  }

  return (
    <Container style={{
      maxWidth: "80%",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: 50
    }}>
      <Container className="text-center">
        <h1>{data.type === "edit" ? "Modifica Lezione" : "Crea Lezione"}</h1>
        <p>
          Comincia a creare le slide personalizzate per ogni argomento che vuoi esporre ai tuoi studenti.
        </p>
      </Container>
      <p><b>Corso: {data.titoloCorso}</b></p>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Select
          id="argomento"
          name="argomento"
          aria-label="Argomento"
          aria-placeholder="Argomento"
          className="mb-3"
          style={{ backgroundColor: Colors.input.background, borderRadius: 10 }}
          defaultValue={data.type === "edit" ? data.fkArgomento : ""}
        >
          {
            data.argomenti.map((argomento: any) => (
              <option value={argomento.idArgomento}>{argomento.titoloArgomento}</option>
            ))
          }
        </Form.Select>

        <Row>
          <Col>
            <FormInput
              id="titoloLezione"
              type="text"
              placeholder="Titolo"
              required={true}
              defaultValue={data.type === "edit" ? data.titoloLezione : ""}
            />
          </Col>
        </Row>

        <FormInput
          id="descrizioneLezione"
          type="textarea"
          placeholder="Descrizione"
          style={{ height: 300 }}
          required={true}
          onChange={onChangeDescription}
          defaultValue={data.type === "edit" ? data.descrizioneLezione : ""}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span>{descriptionLength}/5000</span>
        </div>
        <MainButton type="submit" text="Genera Slide" loading={loading} style={{ marginTop: 20 }} />
      </Form>
    </Container>

  );
}